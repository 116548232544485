import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { UsersOnBoardingEffects } from './+state/effects/users-on-boarding.effects';
import { UsersShowHomeAvailabilityTipEffects } from './+state/effects/users-show-home-availability-tip.effects';
import { UsersEffects } from './+state/users.effects';
import { USERS_FEATURE_KEY, usersReducer } from './+state/users.reducer';
import { UsersStoreService } from './users-store.service';

export function provideUsersStore(): Provider | EnvironmentProviders[] {
  return [
    provideState({ name: USERS_FEATURE_KEY, reducer: usersReducer }),
    provideEffects(UsersEffects, UsersOnBoardingEffects, UsersShowHomeAvailabilityTipEffects),
    UsersStoreService,
  ];
}
