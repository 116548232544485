import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserService } from '@techniek-team/oauth';
import { PersonContract } from '@tutor-app/person-contracts';
import { environment } from '@tutor-app/environments';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadPersonApi {
  private readonly httpClient = inject(HttpClient);
  private readonly userService = inject(UserService);

  /**
   * Get the use data of the user
   */
  public execute(personId?: string): Observable<PersonContract> {
    let observable: Observable<PersonContract>;
    if (personId) {
      observable = this.httpClient.get<PersonContract>(
        `${environment.perza.url}${environment.perza.iri}/people/${personId}`,
      );
    } else {
      observable = this.userService
        .currentUser()
        .pipe(
          switchMap((user) =>
            this.httpClient.get<PersonContract>(
              `${environment.perza.url}${environment.perza.iri}/people/${user.id}`,
            ),
          ),
        );
    }

    return observable;
  }
}
