export class InvalidTimeStringError extends Error {
  constructor() {
    super(
      'Invalid string to set the time by string it should match the regex `/^(\\d{1,2}):(\\d{2})$/`',
    );
    // see: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = InvalidTimeStringError.name; // stack traces display correctly now
  }
}
