import { Location } from '@angular/common';
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Params,
  Router,
  RouterStateSnapshot,
  Routes,
  UrlTree,
} from '@angular/router';
import { ToastController } from '@ionic/angular/standalone';
import { Storage } from '@ionic/storage-angular';
import {
  AuthConfig,
  CHECK_USER_ROLES,
  InvalidRoleError,
  InvalidTokenError, oauthAuthenticationGuard,
  OAuthService,
  ProfileCallFailsError,
  UserService,
} from '@techniek-team/oauth';
import { environment } from '@tutor-app/environments';
import { firstValueFrom } from 'rxjs';
import { AppComponent } from './app/app.component';

export const ROUTES: Routes = [
  {
    path: 'login',
    title: 'Werken Bij Lyceo - Login',
    canActivate: [],
    loadComponent: () => import('@tutor-app/login-page').then((m) => m.LoginPage),
  },
  {
    path: 'help',
    title: 'Werken Bij Lyceo - Help',
    loadChildren: () => import('@tutor-app/help').then((m) => m.ROUTES),
    canActivate: [oauthAuthenticationGuard],
    data: { grants: ['ROLE_SKOLEO_TT_PLANNER_TUTOR'] },
  },
  {
    path: 'geen-toegang',
    title: 'Werken Bij Lyceo - Geen toegang',
    canActivate: [],
    loadChildren: () => import('@tutor-app/access-denied').then((m) => m.ROUTES),
  },
  {
    path: 'sollicitatie',
    title: 'Werken Bij Lyceo - Sollicitatie',
    loadChildren: () =>
      import('./app/job-application/job-application.routes').then((m) => m.ROUTES),
    canActivate: [oauthAuthenticationGuard],
    data: { grants: environment.environment === 'local' ? [] : ['GRANT_APPLICANT'] },
  },
  {
    path: '',
    canActivate: [oauthAuthenticationGuard],
    component: AppComponent,
    data: { grants: ['ROLE_SKOLEO_TT_PLANNER_TUTOR'] },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        title: 'Werken Bij Lyceo - Home',
        loadChildren: () => import('./app/home/home.routes').then((m) => m.ROUTES),
      },
      {
        path: 'agenda',
        title: 'Werken Bij Lyceo - Agenda',
        loadChildren: () => import('./app/calendar/calendar.routes').then((m) => m.ROUTES),
      },
      {
        path: 'opdrachten',
        title: 'Werken Bij Lyceo - Opdrachten',
        loadChildren: () => import('./app/assignments/assignments.routes').then((m) => m.ROUTES),
      },
      {
        path: 'uitbetalingen',
        title: 'Werken Bij Lyceo - Uitbetalingen',
        loadChildren: () => import('./app/payout/payout/payout.routes').then((m) => m.ROUTES),
      },
      {
        path: 'uitbetalingen/:id',
        loadChildren: () =>
          import('./app/payout/payout-detail/payout-detail.routes').then((m) => m.ROUTES),
      },
      {
        path: 'opdrachten/toevoegen',
        title: 'Werken Bij Lyceo - Opdracht Toevoegen',
        loadChildren: () =>
          import(
            './app/assignments/assignment-coaching-create/assignment-coaching-create.routes'
          ).then((m) => m.ROUTES),
      },
      {
        path: 'opdrachten/:id',
        title: 'Werken Bij Lyceo - Opdrachten',
        loadChildren: () =>
          import('./app/assignments/assignment-detail/assignment-detail.routes').then(
            (m) => m.ROUTES,
          ),
      },
      {
        path: 'mijn-gegevens',
        title: 'Werken Bij Lyceo - Mijn Gegevens',
        loadChildren: () => import('./app/profile/profile.routes').then((m) => m.ROUTES),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];
