import { inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { Observable } from 'rxjs';
import { usersActions } from './+state/users.actions';
import { UsersSelectors } from './+state/users.selectors';

@Injectable()
export class UsersStoreService {
  private readonly store = inject(Store);

  private readonly actions = inject(Actions);

  public loading$: Observable<boolean> = this.store.pipe(select(UsersSelectors.loading));

  public loaded$: Observable<boolean> = this.store.pipe(select(UsersSelectors.loaded));

  public user$ = this.store.pipe(select(UsersSelectors.user)).pipe(isDefined());
  public user = this.store.selectSignal(UsersSelectors.user);

  public loadingAvatar = this.store.selectSignal(UsersSelectors.loadingAvatar);

  public isSubmitting = this.store.selectSignal(UsersSelectors.isSubmitting);

  public showHomeAvailabilityTip = this.store.selectSignal(UsersSelectors.showHomeAvailabilityTip);

  public onBoarding = this.store.selectSignal(UsersSelectors.showOnBoarding);

  public init(): void {
    this.store.dispatch(usersActions.initUser());
  }

  public reloadUser(): Promise<void> {
    this.store.dispatch(usersActions.reloadUser());

    return Promise.race([
      firstEmitFrom(this.actions.pipe(ofType(usersActions.loadUserSuccess))).then(() => undefined),
      firstEmitFrom(this.actions.pipe(ofType(usersActions.loadUserFailure))).then(() => undefined),
    ]);
  }

  public updateUser(request: ReturnType<typeof usersActions.updateUser>['request']): void {
    this.store.dispatch(usersActions.updateUser({ request: request }));
  }

  public postAvatar(file: Blob): void {
    this.store.dispatch(usersActions.postAvatar({ file: file }));
  }

  public cameraFailure(error: unknown): void {
    this.store.dispatch(usersActions.postAvatarFailure({ error: error }));
  }

  public deleteAvatar(): void {
    this.store.dispatch(usersActions.deleteAvatar());
  }

  public hideHomeAvailabilityTip(): void {
    this.store.dispatch(usersActions.setUserHomeAvailabilityTip({ value: false }));
  }
}
