import {
  APP_INITIALIZER,
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
  Provider,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  browserTracingIntegration,
  init as SentryAngularInit,
  rewriteFramesIntegration,
  TraceService,
} from '@sentry/angular';
import { init } from '@sentry/capacitor';
import { SentryErrorHandler, SentryWebConfig } from '@techniek-team/sentry-web';
import { SentryCapacitorConfig } from './sentry-capacitor.config';

/**
 * @deprecated
 */
@NgModule({})
export class SentryCapacitorModule {
  //eslint-disable-next-line max-lines-per-function
  public static forRoot(
    options: SentryCapacitorConfig & { enableTracing: boolean },
  ): ModuleWithProviders<SentryCapacitorModule> {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mergedOptions: any = { ...new SentryCapacitorConfig(), ...options };
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const integrations: any[] = [];
    const tracingProviders: Provider[] = [];

    integrations.push(rewriteFramesIntegration());
    if (options.enableTracing) {
      integrations.push(browserTracingIntegration());
      tracingProviders.push({ provide: TraceService, deps: [Router] });
      tracingProviders.push({
        provide: APP_INITIALIZER,
        useFactory: (): Function => (): void => {
          /* empty */
        },
        deps: [TraceService],
        multi: true,
      });
    }

    init(
      {
        ...mergedOptions,
        enabled: true,
        defaultIntegrations: false,
        integrations: integrations,
      },
      SentryAngularInit,
    );

    return {
      ngModule: SentryCapacitorModule,
      providers: [
        { provide: SentryCapacitorConfig, useValue: options },
        { provide: SentryWebConfig, useExisting: SentryCapacitorConfig },
        { provide: SentryErrorHandler },
        { provide: ErrorHandler, useExisting: SentryErrorHandler },
        ...tracingProviders,
      ],
    };
  }
}
