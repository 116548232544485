import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { Exclude, Expose, Type } from 'class-transformer';
import { LocationDetailed } from '../location/location-detailed.model';
import { TimeRangeModel } from '../time-range.model';
import { EventGroup } from './event-group.model';

export class SelectionEvent extends JsonLd {
  @Type(() => Date)
  @Expose()
  public date!: Date;

  @Type(() => LocationDetailed)
  @Expose()
  public location!: LocationDetailed;

  @Type(() => EventGroup)
  @Expose()
  public groups!: EventGroup[];

  @Type(() => TimeRangeModel)
  @Expose()
  public participantTimeRange!: TimeRangeModel;

  /**
   * Returns the DateTimeRange of the combine date and participantTimeRange
   */
  @Exclude() public get timeRange(): TsRange {
    return this.participantTimeRange.convertToTsRange(this.date);
  }
}
