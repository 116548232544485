import { ENVIRONMENT_INITIALIZER, inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular/standalone';
import { OAuthService } from '@techniek-team/oauth';
import { firstValueFrom } from 'rxjs';

function escapeStringRegexp(value: string) {
  let specials = ['-', '[', ']', '/', '{', '}', '(', ')', '*', '+', '?', '.', '^', '$', '|'];
  let regex = RegExp('[' + specials.join('\\') + ']', 'g');

  return value.replace(regex, '\\$&');
}

async function deepLinkListener(deeplinkProtocol: string) {
  const platform = inject(Platform);
  const oauthService = inject(OAuthService);
  const router = inject(Router);
  const zone = inject(NgZone, { optional: true });
  await platform.ready();
  if (!platform.is('capacitor')) {
    return false;
  }
  const listenerFunc = async (event: URLOpenListenerEvent) => {
    if (
      event.url.match(/.*\/ios-login\.html/) ||
      event.url.match(new RegExp(escapeStringRegexp(deeplinkProtocol)))
    ) {
      const url: URL = new URL(event.url);
      const queryParams: Record<string, unknown> = {};
      for (let [key, value] of url.searchParams.entries()) {
        queryParams[key] = value;
      }
      const code = queryParams['code'] as string;
      if (code) {
        await firstValueFrom(oauthService.exchangeAuthCodePKCE(code), {
          defaultValue: undefined,
        });
        Browser.close().catch(() => {
          /* an error is thrown when nothing can be closed, this is fine */
        });

        return router.navigate(['/', 'home'], { queryParams: queryParams });
      }
      return false;
    }
    return false;
  };

  return App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
    if (zone) {
      zone.run(() => {
        listenerFunc(event);
      });
      return;
    }
    listenerFunc(event);
  });
}

export function withDeepLinks(config: { deeplinkProtocol: string }) {
  return [
    {
      provide: ENVIRONMENT_INITIALIZER,
      useFactory: () => {
        return () => {
          deepLinkListener(config.deeplinkProtocol);
        };
      },
      multi: true,
    },
  ];
}
