import { JsonLd } from '@techniek-team/class-transformer';
import { memoize } from 'lodash-es';

//eslint-disable-next-line @typescript-eslint/naming-convention
export function isJsonLdObject<T>(entity: T): entity is T & { '@id': string } {
  return entity && typeof entity === 'object' && '@id' in entity;
}

export function parseUuidFromIri(iri: string): string {
  const iriRegex: RegExp = new RegExp(/^\/.*\/([A-z\d\-_]*)/);
  const match: string[] | null = iri.match(iriRegex);
  return match ? (match.pop() ?? iri) : iri;
}

export const jsonLdSelectId = memoize(
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  <T extends any>(entity: T): string => {
    if (typeof entity === 'string') {
      return parseUuidFromIri(entity);
    }

    if (entity instanceof JsonLd) {
      return entity.getId();
    }

    if (isJsonLdObject(entity)) {
      return parseUuidFromIri(entity['@id'] as string);
    }

    console.error(
      'entity can only be a string or an object with an @id property, Warning this breaking NGRX as a whole',
    );
    throw new Error('entity can only be a string or an object with an @id property');
  },
  (entity: unknown) => {
    if (!entity) {
      return '';
    }
    if (typeof entity === 'string') {
      return entity;
    }

    if (entity instanceof JsonLd) {
      return entity.getId();
    }

    if (isJsonLdObject(entity)) {
      return entity['@id'] as string;
    }
    return entity;
  },
);
