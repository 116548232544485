import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectRouter = createFeatureSelector<RouterReducerState>('router');
export const { selectCurrentRoute } = getRouterSelectors();

export const selectRootUrl = createSelector(selectRouter, (router) => {
  let currentRoute = router?.state?.root;
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    if (currentRoute.url.length > 0) {
      return currentRoute.url[0];
    }
  }
  return null;
});
