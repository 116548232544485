import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { denormalize } from '@techniek-team/class-transformer';
import { normalizeNull } from '@techniek-team/common';
import { PersonContract } from '@tutor-app/person-contracts';
import { environment } from '@tutor-app/environments';
import { JobApplication } from '@tutor-app/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { usersActions } from '../users.actions';
import { PersonGetActiveJobApplicationResponse } from './person.response';

@Injectable({
  providedIn: 'root',
})
export class PersonApi {
  private readonly httpClient = inject(HttpClient);

  /**
   * Save the data of the user
   */
  public updatePerson(
    request: ReturnType<typeof usersActions.updateUser>['request'],
  ): Observable<PersonContract> {
    const url: string = `${environment.perza.url}${environment.perza.iri}/people/${request.personId}`;

    return this.httpClient.put<PersonContract>(url, {
      hasDriversLicense: request.hasDriversLicense,
      hasCar: request.hasCar,
      iban: request.iban,
      phoneNumber: request.phoneNumber,
      mainAddress: {
        street: request.mainAddress?.street,
        streetNumber: request.mainAddress?.streetNumber,
        streetNumberSuffix: request.mainAddress?.streetNumberSuffix,
        postalCode: request.mainAddress?.postalCode,
        town: request.mainAddress?.town,
      },
      attendsEducationPrograms: request.attendsEducationPrograms.map((value) => ({
        '@id': value.id,
        'isMain': value.isMain,
        'yearStart': value.yearStart,
        'yearCompleted': value.yearCompleted,
        'instituteName': value.instituteName,
        'programmeName': value.programmeName,
      })),
    });
  }

  /**
   * Check if the user has any selection events
   */
  public getActiveJobApplication(personId: string): Observable<JobApplication> {
    const url: string = `${environment.perza.url}${environment.perza.iri}/people/${personId}/active_job_application`;
    const headers: HttpHeaders = new HttpHeaders().set('content-type', 'application/ld+json');
    return this.httpClient
      .get<PersonGetActiveJobApplicationResponse>(url, { headers: headers })
      .pipe(
        map((response) => normalizeNull(response)),
        map((response) => denormalize(JobApplication, response as object)),
      );
  }

  /**
   * Upload the users avatar
   */
  public postAvatar(personId: string, file: Blob): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    const url: string = `${environment.perza.url}${environment.perza.iri}/people/${personId}/profile_photo`;

    return this.httpClient.post<void>(url, formData);
  }

  /**
   * Upload the users avatar
   */
  public deleteAvatar(personId: string): Observable<void> {
    const url: string = `${environment.perza.url}${environment.perza.iri}/people/${personId}/profile_photo`;

    return this.httpClient.delete<void>(url);
  }
}
