import { Expose, Type } from 'class-transformer';
import { Agreement } from './agreement.model';

export class ApplicationAgreement {
  @Expose() public id!: string;

  @Type(() => Agreement)
  @Expose()
  public agreement!: Agreement;
}
