/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject } from '@angular/core';
import { Color, ToastButton } from '@ionic/core';
import { ofType } from '@ngrx/effects';
import { Action, ActionCreator } from '@ngrx/store';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { Observable, pipe, tap, UnaryFunction } from 'rxjs';

export interface HandleEndpointToastOptions<ActionType extends ActionCreator> {
  message: string | ((action: ReturnType<ActionType>) => string);

  duration?: number;

  color?: Color;

  cssClass?: string | string[];

  position?: 'top' | 'bottom' | 'middle';

  buttons?: (ToastButton | string)[];
}

//eslint-disable-next-line max-lines-per-function
export function handleEndpointFailure<
  ActionType extends ActionCreator = ActionCreator<
    string,
    (props: { error: unknown }) => { error: unknown } & Action<string>
  >,
>(
  failureAction: ActionType,
  options: HandleEndpointToastOptions<ActionType> = {
    message: 'Er is iets misgegaan. Probeer het opnieuw.',
  },
): UnaryFunction<Observable<Action>, Observable<unknown>> {
  options = {
    duration: 10000,
    color: 'dark',
    buttons: [{ text: 'Sluiten', role: 'cancel' }],
    position: 'bottom',
    ...options,
  };

  const sentryErrorHandler: SentryErrorHandler = inject(SentryErrorHandler);
  const toastService: ToastService = inject(ToastService);
  return pipe(
    ofType(failureAction),
    tap((action) => {
      const message: string =
        typeof options?.message === 'string'
          ? options.message
          : options?.message(action as ReturnType<ActionType>);
      return Promise.all([
        sentryErrorHandler.captureError((action as { error: unknown }).error),
        toastService.error({
          duration: options?.duration as number,
          buttons: options?.buttons,
          ...options,
          message: sentryErrorHandler.extractMessage(
            (action as { error: unknown }).error,
            message as string,
          ),
        }),
      ]);
    }),
  );
}

export function handleEndpointSuccess<
  ActionType extends ActionCreator = ActionCreator<string, (props: any) => any & Action<string>>,
>(
  successAction: ActionType,
  options: HandleEndpointToastOptions<ActionType> = {
    message: 'De actie is succesvol opgeslagen.',
  },
): UnaryFunction<Observable<Action>, Observable<unknown>> {
  options = {
    duration: 3000,
    buttons: [{ text: 'Sluiten', role: 'cancel' }],
    position: 'bottom',
    ...options,
  };
  const toastService: ToastService = inject(ToastService);
  return pipe(
    ofType(successAction),
    tap((action) => {
      const message: string =
        typeof options.message === 'string'
          ? options.message
          : options.message(action as ReturnType<ActionType>);

      return toastService.create({
        duration: options?.duration as number,
        buttons: options?.buttons,
        ...options,
        message: message,
      });
    }),
  );
}
