import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@tutor-app/environments';
import { Observable } from 'rxjs';
import { PostTokenRequest } from './candidate.request';

@Injectable({
  providedIn: 'root',
})
export class CandidateApi {
  private readonly httpClient = inject(HttpClient);

  /**
   * Store the FCM RegisterToken in the backend
   */
  public postToken(request: PostTokenRequest): Observable<void> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/person/current/push-token`;

    return this.httpClient.post<void>(url, {
      token: request.registerToken,
      client: request.client,
      device: request.device,
      deviceId: request.deviceId,
    });
  }
}
