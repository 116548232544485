import { MapAnchorPoint } from '@angular/google-maps';
import { HtmlMarkerInterface } from './html-marker.interface';

export interface HTMLMarkerOptions {
  position: google.maps.LatLng | google.maps.LatLngLiteral;
  content: HTMLElement;
}

//eslint-disable-next-line max-lines-per-function,max-len
export function createHtmlMarker(
  constructorOptions: HTMLMarkerOptions,
): google.maps.OverlayView & MapAnchorPoint & HtmlMarkerInterface {
  class HTMLMarker extends google.maps.OverlayView implements MapAnchorPoint {
    /**
     * Container element of the overlay
     */
    private element: HTMLElement;

    /**
     * Boolean to check if the element is already appended to the google maps pane
     * To makes sure that a second draw trigger doesn't append the overlay again.
     */
    private isAppended: boolean = false;

    /**
     * The position(coordination) where the overlay needs to be placed on the map.
     */
    private position!: google.maps.LatLng;

    constructor(options: HTMLMarkerOptions) {
      super();

      this.setPosition(options.position);

      this.element = document.createElement('div');
      this.element.style.position = 'absolute';
      this.element.appendChild(options.content);
    }

    /**
     * https://developers.google.com/maps/documentation/javascript/customoverlays#introduction
     * @inheritDoc
     */
    public override draw(): void {
      this.appendDivToOverlay();
      this.positionDiv();
    }

    /**
     * https://developers.google.com/maps/documentation/javascript/customoverlays#introduction
     * @inheritDoc
     */
    public override onRemove(): void {
      this.element.parentNode?.removeChild(this.element);
      this.isAppended = false;
    }

    /**
     * Method use to set the position of the html-marker.
     * @param position
     */
    public setPosition(position: google.maps.LatLng | google.maps.LatLngLiteral): void {
      if (!this.latLngEquals(this.position, position)) {
        this.position = this.createLatLng(position);
      }
    }

    /**
     * Helper method to get the current position.
     */
    public getPosition(): google.maps.LatLng {
      return this.position;
    }

    /**
     * When the html-marker is given as parameter to a map-info window instance.
     * the windows uses this to anchor itself to.
     * @inheritDoc
     */
    public getAnchor(): google.maps.MVCObject {
      return this;
    }

    /**
     * The method add {@see self.element} to the google maps pane.
     * It also set isAppended to true so that it only adds it once.
     */
    private appendDivToOverlay(): void {
      if (!this.isAppended) {
        const panes: google.maps.MapPanes = this.getPanes() as google.maps.MapPanes;
        panes.overlayMouseTarget.appendChild(this.element);
        this.isAppended = true;
      }
    }

    /**
     * This method get the x,y pixel position from Google.maps based on the
     * given Latitude and Longitude. It the sets the left and top position of
     * the Html-marker.
     *
     * It als retrieves the DOMRect of the marker to detrime it's width and height
     * to center the marker exactly on the position given to this marker
     */
    private positionDiv(): void {
      const point: google.maps.Point = this.getProjection().fromLatLngToDivPixel(
        this.position,
      ) as google.maps.Point;
      const containerBoundingRect: DOMRect = this.element.getBoundingClientRect();
      if (point) {
        this.element.style.left = `${point.x - containerBoundingRect.width / 2}px`;
        this.element.style.top = `${point.y - containerBoundingRect.height / 2}px`;
      }
    }

    //noinspection JSMethodCanBeStatic
    /**
     * Creates a Google LatLng class bases on the given position.
     */
    private createLatLng(
      position: google.maps.LatLng | google.maps.LatLngLiteral,
    ): google.maps.LatLng {
      if (position instanceof google.maps.LatLng) {
        return position;
      }

      return new google.maps.LatLng(position);
    }

    //noinspection JSMethodCanBeStatic
    /**
     * Compares the two given positions and check if they are equal.
     */
    private latLngEquals(
      positionA: google.maps.LatLng | undefined,
      positionB: google.maps.LatLng | google.maps.LatLngLiteral,
    ): boolean {
      if (!positionA) {
        return false;
      }

      if (positionB instanceof google.maps.LatLng) {
        return positionA.equals(positionB);
      }

      return positionA.lat() === positionB.lat && positionA.lng() === positionB.lng;
    }
  }

  return new HTMLMarker(constructorOptions);
}
