import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { LyceoUser, UserService } from '@techniek-team/oauth';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { handleEndpointFailure, jsonLdSelectId } from '@techniek-team/tt-ngrx';
import { catchError, of, tap } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { LoadPersonApi } from './api/load-person.api';
import { PersonApi } from './api/person.api';
import { usersActions } from './users.actions';
import { UsersSelectors } from './users.selectors';

@Injectable()
export class UsersEffects implements OnInitEffects {
  private readonly loadPersonApi = inject(LoadPersonApi);
  private readonly personApi = inject(PersonApi);
  private readonly actions$ = inject(Actions);
  private readonly toastService = inject(ToastService);
  private readonly sentryErrorHandler = inject(SentryErrorHandler);
  private readonly store = inject(Store);
  private readonly storage = inject(Storage);
  private readonly router = inject(Router);
  private readonly userService = inject(UserService<LyceoUser>);

  public readonly initAccount = createEffect(() =>
    this.userService.currentUser().pipe(
      switchMap((user) => of(usersActions.loadAccountSuccess({ user: user }))),
      catchError((error) => of(usersActions.loadAccountFailure({ error: error }))),
    ),
  );

  private loadUserEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(
        usersActions.initUser,
        usersActions.postAvatarSuccess,
        usersActions.deleteAvatarSuccess,
      ),
      mergeMap(() => {
        return this.loadPersonApi.execute().pipe(
          map((response) => usersActions.loadUserSuccess({ user: response })),
          catchError((error) => of(usersActions.loadUserFailure({ error: error }))),
        );
      }),
    ),
  );

  private loadUserFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.loadUserFailure, usersActions.loadUserFailure),
        tap((action) => {
          return this.sentryErrorHandler.captureError(action.error).then(() =>
            this.toastService.error({
              message: 'Er is iets mis gegaan bij het ophalen van je gegevens',
              duration: 3000,
              buttons: [{ text: 'Sluiten', role: 'cancel' }],
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  private reloadUserEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.reloadUser),
      mergeMap((action) => {
        return this.loadPersonApi.execute().pipe(
          map((response) => usersActions.loadUserSuccess({ user: response })),
          //eslint-disable-next-line max-len
          catchError((error) =>
            of(
              usersActions.loadUserFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  private updateUserEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.updateUser),
      mergeMap((action) => {
        return this.personApi.updatePerson(action.request).pipe(
          map((response) => usersActions.updateUserSuccess({ user: response })),
          catchError((error) => of(usersActions.updateUserFailure({ error: error }))),
        );
      }),
    ),
  );

  private updateUserSuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.updateUserSuccess),
        tap(() => {
          return this.toastService
            .create({
              message: 'Je gegevens zijn bijgewerkt!',
              duration: 3000,
              buttons: [{ text: 'Sluiten', role: 'cancel' }],
            })
            .then(() => {
              return this.router.navigate(['mijn-gegevens']);
            });
        }),
      ),
    { dispatch: false },
  );

  private updateUserFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.updateUserFailure),
        tap(() => {
          return this.toastService.error({
            message: 'Het bijwerken van je gegevens is mislukt.',
            duration: 3000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          });
        }),
      ),
    { dispatch: false },
  );

  private postAvatarEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.postAvatar),
      concatLatestFrom(() => this.store.select(UsersSelectors.user)),
      mergeMap(([action, user]) => {
        return this.personApi.postAvatar(jsonLdSelectId(user), action.file).pipe(
          map(() => usersActions.postAvatarSuccess()),
          catchError((error) => of(usersActions.postAvatarFailure({ error: error }))),
        );
      }),
    ),
  );

  private postAvatarSuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.postAvatarSuccess),
        tap(() => {
          return this.toastService.create({
            message: 'Je profielfoto is succesvol opgeslagen!',
            duration: 3000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          });
        }),
      ),
    { dispatch: false },
  );

  private postAvatarFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.postAvatarFailure),
        tap(() => {
          return this.toastService.error({
            message: 'Oeps! Er is iets misgegaan bij het opslaan van je profielfoto.',
            duration: 3000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          });
        }),
      ),
    { dispatch: false },
  );

  private deleteAvatarEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.deleteAvatar),
      concatLatestFrom(() => this.store.select(UsersSelectors.user)),
      mergeMap(([_, user]) => {
        return this.personApi.deleteAvatar(jsonLdSelectId(user)).pipe(
          map(() => usersActions.deleteAvatarSuccess()),
          catchError((error) => of(usersActions.deleteAvatarFailure({ error: error }))),
        );
      }),
    ),
  );

  private deleteAvatarSuccessEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.deleteAvatarSuccess),
        tap(() => {
          return this.toastService.create({
            message: 'Profielfoto verwijderd!',
            duration: 3000,
            buttons: [{ text: 'Sluiten', role: 'cancel' }],
          });
        }),
      ),
    { dispatch: false },
  );

  private deleteAvatarFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        handleEndpointFailure(usersActions.deleteAvatarFailure, {
          message: 'Oeps! Er is iets misgegaan bij het verwijderen.',
        }),
      ),
    { dispatch: false },
  );

  public ngrxOnInitEffects(): Action {
    return usersActions.initUser();
  }
}
