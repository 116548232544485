import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors, withJsonpSupport } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom, LOCALE_ID, Provider } from '@angular/core';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  bootstrapApplication,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  NoPreloading,
  PreloadAllModules,
  provideRouter,
  Router,
  RouteReuseStrategy, UrlTree,
  withPreloading,
} from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Drivers } from '@ionic/storage';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { jsonLdInterceptor, provideIonicStorage } from '@techniek-team/common';
import { provideTTGoogleMaps, withGeometry } from '@techniek-team/google-maps';
import {
  authInterceptor,
  LyceoUser, OAuthService,
  provideTtAuth,
  UserService,
  withUserRoles,
} from '@techniek-team/oauth';
import { withDeepLinks, withInAppBrowser } from '@techniek-team/oauth-capacitor';
import { withCapacitor } from '@techniek-team/sentry-capacitor';
import { provideSentry, SentryEnvironment } from '@techniek-team/sentry-web';
import { provideUsersStore } from '@tutor-app/data-access-users';
import { environment } from '@tutor-app/environments';
import 'hammerjs';
import 'reflect-metadata';
import { checkUserRoles } from '@tutor-app/permissions';
import { setDefaultOptions } from 'date-fns';
import { nl } from 'date-fns/locale';
import { addIcons } from 'ionicons';
import { arrowBack } from 'ionicons/icons';
import { firstValueFrom } from 'rxjs';
import { MainComponent } from './main.component';
import { ROUTES } from './main.routing';

if (environment.production) {
  enableProdMode();
}

defineCustomElements(window);
addIcons({ arrowBack: arrowBack });
registerLocaleData(localeNl, 'nl');
setDefaultOptions({
  locale: nl,
  weekStartsOn: 1,
});

bootstrapApplication(MainComponent, {
  providers: [
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    { provide: MAT_DATE_LOCALE, useValue: nl },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({
      useSetInputAPI: true,
      backButtonIcon: 'arrow-back',
      backButtonText: '',
    }),
    provideIonicStorage({
      name: '__tutorapp-web-app',
      driverOrder: environment.production
        ? [Drivers.IndexedDB, Drivers.LocalStorage]
        : [Drivers.LocalStorage],
    }),
    provideRouter(
      ROUTES,
      withPreloading(environment.production ? PreloadAllModules : NoPreloading),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptors([jsonLdInterceptor, authInterceptor]), withJsonpSupport()),
    provideDateFnsAdapter(),
    provideSentry(
      {
        environment: environment.environment,
        dsn: 'https://80c1b7d82ad14b89ad83be74252766b9@errors.techniek-team.nl//24',
        release: environment.release,
        userService: UserService,
        autoSessionTracking: true,
      },
      withCapacitor({}),
    ),
    provideTtAuth(
      {
        clientId: environment.ssoConfig.clientId,
        ssoBaseUrl: environment.ssoConfig.ssoBaseUrl,
        profileBaseUrl: environment.ssoConfig.profileBaseUrl,
        baseUrl: environment.ssoConfig.baseUrl,
        redirectUri: environment.ssoConfig.redirectUri,
        loginUrl: '/login',
        homeUrl: '/home',
        whitelist: ['https://werkenbijapp.lyceo.nl/assets/notices.json'],
        model: LyceoUser,
      },
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      withUserRoles(checkUserRoles as any, UserService as Provider, Router as Provider),
      withInAppBrowser(),
      withDeepLinks({ deeplinkProtocol: 'werkenbijlyceo://' }),
    ),
    provideTTGoogleMaps({ apiKey: environment.googleMapsApiKey }, withGeometry()),
    provideStore(),
    provideEffects(),
    !environment.production
      ? provideStoreDevtools({
          maxAge: 100, // Retains last 100 states
          logOnly: environment.environment !== SentryEnvironment.LOCAL, // Restrict extension to log-only mode
          autoPause: true, // Pauses recording actions and state changes when the extension window is not open
          trace: true,
          connectInZone: true,
        })
      : [],
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    importProvidersFrom(HammerModule),
    provideUsersStore(),
  ],
})
  //eslint-disable-next-line no-console
  .catch((err) => console.log(err));
