import { JsonLd } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { LocationDetailed } from '../location/location-detailed.model';

export class Applicant extends JsonLd {
  /**
   * Soms endpoint return the fullName property if this is set we use this instead
   * of creating it our self.
   */
  private completeName!: string;

  /**
   * Sets the fullName if given.
   * This property is stored in {@see completeName}
   */
  @Expose()
  public set fullName(fullName: string) {
    if (fullName) {
      this.completeName = fullName;
    }
  }

  /**
   * returns the full name of the person. This is either the {@see completeName}
   * property or an combined string of {@see firstName}, {@see middleName}
   * and {@see lastName}
   */
  public get fullName(): string {
    return (
      this.completeName ??
      [this.firstName, this.middleName, this.lastName].filter((part) => !!part).join(' ')
    );
  }

  /**
   * First name of the person.
   */
  @Expose() public firstName!: string;

  /**
   * Middle name of this person, if any. for example "van der"
   */
  @Expose() public middleName?: string;

  /**
   * Surname of this person
   */
  @Expose() public lastName!: string;

  /**
   * The birthdate of the person.
   */
  @Type(() => Date)
  @Expose()
  public dateOfBirth!: Date;

  /**
   * The phone number of the person.
   */
  @Expose() public phoneNumber?: string;

  /**
   * The emailAddress of the person.
   */
  @Expose() public emailAddress!: string;

  @Type(() => LocationDetailed)
  @Expose()
  public mainAddress!: LocationDetailed;
}
