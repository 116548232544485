import { Expose, Type } from 'class-transformer';
import { SubjectModel } from '../subject.model';

export class SubjectGrade {
  @Type(() => SubjectModel)
  @Expose()
  public subject!: SubjectModel;

  @Expose() public grade!: number;
}
