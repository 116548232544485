import { Component, inject, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { IonApp, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { Storage } from '@ionic/storage-angular';
import { PushNotificationService } from './app/core/push-notification/push-notification.service';

/**
 * This component is nothing more than a bootstrap component. Facilitating the routing within the
 * single page application.
 */
@Component({
  selector: 'app-root',
  template: '<ion-app><ion-router-outlet [animated]="true" /></ion-app>',
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class MainComponent implements OnInit {
  private readonly platform = inject(Platform);

  private readonly router = inject(Router);

  private readonly pushService = inject(PushNotificationService);

  private readonly storage = inject(Storage);

  private readonly zone = inject(NgZone);

  public ngOnInit(): void {
    this.checkLocalStorage();
    this.initializeApp();
  }

  /**
   * @private
   */
  private initializeApp(): void {
    this.platform.ready().then(() => {
      this.storage.get('show_help').then((showHelp) => {
        if (showHelp === false) {
          return;
        }
        return this.router.navigate(['/help']);
      });

      if (this.platform.is('capacitor')) {
        //noinspection JSIgnoredPromiseFromCall
        // todo this package is not maintained anymore
        //NavigationBar.setColor({ color: '#1F2A44', darkButtons: false });
        SplashScreen.hide();
        this.initializeDeepLinks();
        this.pushService.redirectOnPushNotification();
      }

      document.getElementById('init-app-loading')?.remove();
    });
  }

  /**
   * Match the path when we redirect the user from an * external url from the inAppBrowser or the mobile browser
   * @private
   */
  private initializeDeepLinks(): Promise<unknown> {
    return App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        if (event.url.match(/.*\/ios-login\.html/) || event.url.match(/werkenbijlyceo:\/\//)) {
          return;
        }

        //todo is this still needed?
        console.log('is this still needed?');
        // Example url: https://werkenbijapp.lyceo.nl/tab2
        // slug = /tab2
        const parts: string[] = event.url.split('.nl');
        if (parts.length > 1) {
          return this.router.navigateByUrl(parts.pop() as string);
        }
        // If no match, do nothing - let regular routing
        // logic take over
        return Promise.resolve(false);
      });
    });
  }

  /**
   * Checks if device is able to use Storage and initializes it.
   */
  private async checkLocalStorage(): Promise<void> {
    try {
      await this.storage.create();
    } catch (e) {
      alert(
        'Er kunnen geen gegevens worden opgeslagen omdat je een' +
          ' verouderde browser of privé- of incognitomodus gebruikt. De app' +
          ' zal helaas niet werken.',
      );
    }
  }
}
