import { Component, inject } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faBriefcase,
  faCalendarDays,
  faHome,
  faMoneyBill,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import {
  IonLabel,
  IonTabBar,
  IonTabButton,
  IonTabs,
  ModalController,
  PopoverController,
} from '@ionic/angular/standalone';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonLabel,
    FaIconComponent,
  ],
})
export class AppComponent {
  protected readonly popoverController = inject(PopoverController);

  protected readonly modalController = inject(ModalController);

  protected readonly faHome = faHome;

  protected readonly faCalendarDays = faCalendarDays;

  protected readonly faBriefcase = faBriefcase;

  protected readonly faMoneyBill = faMoneyBill;

  protected readonly faUser = faUser;

  public closeAllOverlays(): Promise<void> {
    return Promise.all([
      this.popoverController.getTop().then((overlay) => {
        if (overlay) {
          return this.popoverController.dismiss();
        }
        return undefined;
      }),
      this.modalController.getTop().then((overlay) => {
        if (overlay) {
          return this.modalController.dismiss();
        }
        return undefined;
      }),
    ]).then();
  }
}
