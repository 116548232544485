import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { InvalidGrantError } from './invalid-grant.error';
import { UserInterface, UserService } from '@techniek-team/oauth';

export function checkUserRoles(userService: UserService, router: Router) {
  return async (currentUser: UserInterface, route: ActivatedRouteSnapshot) => {
    const grants: string[] = fetchGrants(route);

    if (grants.length === 0) {
      return;
    }

    const isGranted: boolean = userService.isGranted(grants);

    if (!isGranted) {
      const error = new InvalidGrantError('InvalidGrantError!', grants);
      await handleError(error, userService, router);
      throw error;
    }
  };
}

function fetchGrants(route: ActivatedRouteSnapshot): string[] {
  if (route.data && 'grants' in route.data) {
    return route.data['grants'];
  }
  return [];
}

function handleError(error: Error, userService: UserService, router: Router) {
  switch (true) {
    case error instanceof InvalidGrantError:
      if (userService.hasRole('ROLE_SKOLEO_TT_PLANNER_TUTOR')) {
        return router.navigateByUrl('/home');
      } else if (userService.hasRole('GRANT_APPLICANT')) {
        return router.navigateByUrl('/sollicitatie');
      }
      return router.navigateByUrl('/geen-toegang');
  }

  return Promise.resolve(false);
}
