import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapConfig } from './google-api-loader';
import { TtMapHtmlMarkerComponent } from './map-html-marker/tt-map-html-marker.component';

/**
 * @deprecated
 */
@NgModule({
  exports: [TtMapHtmlMarkerComponent, GoogleMapsModule],
  imports: [TtMapHtmlMarkerComponent, CommonModule, GoogleMapsModule],
  providers: [provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())],
})
export class TTGoogleMapsModule {
  public static forRoot(config: GoogleMapConfig): ModuleWithProviders<TTGoogleMapsModule> {
    return {
      ngModule: TTGoogleMapsModule,
      providers: [
        {
          provide: GoogleMapConfig,
          useValue: config,
        },
      ],
    };
  }
}
