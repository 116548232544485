import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapConfig, GoogleMapLibraryEnum } from './google-api-loader';

export function withDrawing() {
  return GoogleMapLibraryEnum.DRAWING;
}
export function withGeometry() {
  return GoogleMapLibraryEnum.GEOMETRY;
}
export function withLocalContext() {
  return GoogleMapLibraryEnum.LOCAL_CONTEXT;
}
export function withPlaces() {
  return GoogleMapLibraryEnum.PLACES;
}
export function withVisualization() {
  return GoogleMapLibraryEnum.VISUALIZATION;
}

export function provideTTGoogleMaps(
  config: Omit<GoogleMapConfig, 'libraries'>,
  ...features: GoogleMapLibraryEnum[]
) {
  const library: GoogleMapLibraryEnum[] = [...features];
  const completeConfig: GoogleMapConfig = {
    ...new GoogleMapConfig(),
    ...config,
    libraries: library,
  };
  return [
    {
      provide: GoogleMapConfig,
      useValue: completeConfig,
    },
  ];
}
