import { JobApplicationStateEnum } from '@tutor-app/enums';
import { Expose, Type } from 'class-transformer';
import { Applicant } from '../person/applicant.model';
import { SelectionEventInvitation } from '../selection-event/selection-event-invitation.model';
import { SelectionEventParticipation } from '../selection-event/selection-event-participation.model';
import { TemporarilyJsonLd } from '../temporarily-json-ld';
import { ApplicationAgreement } from './application-agreement.model';
import { SubjectGrade } from './subject-grade.model';

export class JobApplication extends TemporarilyJsonLd {
  @Type(() => SelectionEventInvitation)
  @Expose()
  public selectionEventInvitation?: SelectionEventInvitation;

  @Type(() => SelectionEventParticipation)
  @Expose()
  public selectionEventParticipation!: SelectionEventParticipation;

  @Expose() public state!: JobApplicationStateEnum;

  @Type(() => Applicant)
  @Expose()
  public person!: Applicant;

  @Expose() public highestPriorEducation!: string;

  @Type(() => SubjectGrade)
  @Expose()
  public subjectGrades!: SubjectGrade[];

  @Type(() => ApplicationAgreement)
  @Expose()
  public agreements!: ApplicationAgreement[];

  @Expose() public levelOfCurrentStudy!: string;

  @Expose() public currentStudy?: string;

  @Expose() public gradeOfCurrentStudy?: string;

  @Expose() public gender?: string;
}
