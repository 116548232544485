import { Injectable, Provider } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { InAppBrowserCreateOptions, InAppBrowserService } from '@techniek-team/oauth';

export function provideInAppBrowserCapacitorService(): Provider {
  return {
    provide: InAppBrowserService,
    useClass: InAppBrowserCapacitorService,
  };
}

@Injectable()
export class InAppBrowserCapacitorService extends InAppBrowserService {
  public create(options: InAppBrowserCreateOptions): Promise<void> {
    if (options && typeof options.callback === 'function') {
      Browser.addListener('browserFinished', () => {
        (options.callback as () => void)();
        Browser.removeAllListeners();
      });
    }

    return Browser.open({
      url: options.url,
      windowName: options.windowName,
      presentationStyle: 'popover',
    });
  }
}
