import { ErrorHandler } from '@angular/core';
import { init } from '@sentry/capacitor';
import { SentryErrorHandler, SentryWebConfig } from '@techniek-team/sentry-web';
import { SentryCapacitorConfig } from './sentry-capacitor.config';

export function withCapacitor(options: SentryCapacitorConfig) {
  return {
    type: 'withSentry',
    providers: [
      { provide: SentryCapacitorConfig, useValue: options },
      { provide: SentryWebConfig, useExisting: SentryCapacitorConfig },
      { provide: SentryErrorHandler },
      { provide: ErrorHandler, useExisting: SentryErrorHandler },
    ],
    integrations: [],
    init: init,
    options: options,
  };
}
