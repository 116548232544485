import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of, ReplaySubject, share } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

export enum GoogleMapLibraryEnum {
  DRAWING = 'drawing',
  GEOMETRY = 'geometry',
  LOCAL_CONTEXT = 'localContext',
  PLACES = 'places',
  VISUALIZATION = 'visualization',
}

export class GoogleMapConfig {
  public apiKey!: string;

  public libraries?: GoogleMapLibraryEnum[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let google: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleApiLoader {
  private readonly httpClient = inject(HttpClient);

  private readonly config = inject(GoogleMapConfig, { optional: true });

  private loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    if (!this.config?.apiKey) {
      throw new Error('Google Map api key not set. Use TTGoogleMaps.forRoot() to set it');
    }
    this.loadGoogleMapApi();
  }

  public hasGoogle(): boolean {
    return !!(google && google.maps);
  }

  public ready(): Promise<boolean> {
    return firstValueFrom(this.loadingSubject$.pipe(filter((isLoaded) => isLoaded as boolean)), {
      defaultValue: false,
    });
  }

  public ready$(): Observable<boolean> {
    return this.loadingSubject$.pipe(
      filter((isLoaded) => isLoaded),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  private loadGoogleMapApi(): void {
    let libraries: string = '';
    if (this.config?.libraries) {
      libraries = `&libraries=${this.config.libraries.join(',')}`;
    }
    this.httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${this.config?.apiKey}${libraries}`,
        'callback',
      )
      .pipe(
        map(() => true),
        catchError((err) => {
          console.error(err);
          return of(false);
        }),
      )
      .subscribe(() => this.loadingSubject$.next(true));
  }
}
