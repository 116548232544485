<ion-tabs>
  <ion-tab-bar slot="bottom">
    <ion-tab-button (click)="closeAllOverlays()" [selected]="true" tab="home">
      <fa-icon [icon]="faHome" />
      <ion-label>Home</ion-label>
    </ion-tab-button>

    <ion-tab-button (click)="closeAllOverlays()" tab="agenda">
      <fa-icon [icon]="faCalendarDays" />
      <ion-label>Agenda</ion-label>
    </ion-tab-button>

    <ion-tab-button (click)="closeAllOverlays()" tab="opdrachten">
      <fa-icon [icon]="faBriefcase" />
      <ion-label>Diensten</ion-label>
    </ion-tab-button>

    <ion-tab-button (click)="closeAllOverlays()" tab="uitbetalingen">
      <fa-icon [icon]="faMoneyBill" />
      <ion-label>Uitbetalingen</ion-label>
    </ion-tab-button>

    <ion-tab-button (click)="closeAllOverlays()" tab="mijn-gegevens">
      <fa-icon [icon]="faUser" />
      <ion-label>Profiel</ion-label>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
