import { AgreementState } from '@tutor-app/enums';
import { Expose } from 'class-transformer';

export class Agreement {
  @Expose() public id!: string;

  @Expose() public name!: string;

  @Expose() public state!: AgreementState;

  @Expose() public cancellationReason?: string;
}
