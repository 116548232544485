import { Expose, Type } from 'class-transformer';
import { EventGroup } from './event-group.model';

export class SelectionEventInvitation {
  @Expose() public id!: string;

  @Expose() public invitedAt!: string;

  @Expose() public state!: string;

  @Type(() => EventGroup)
  @Expose()
  public eventGroup!: EventGroup;
}
