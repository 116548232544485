import { inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { addDays, isBefore, isDate, parseISO } from 'date-fns';
import { catchError, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { map, mergeMap } from 'rxjs/operators';
import { usersActions } from '../users.actions';

@Injectable()
export class UsersShowHomeAvailabilityTipEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly storage = inject(Storage);

  public ngrxOnInitEffects(): Action {
    return usersActions.initUserHomeAvailabilityTip();
  }

  public initShowHomeAvailabilityTip = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.initUserHomeAvailabilityTip),
      mergeMap(() => fromPromise(this.storage.create())),
      mergeMap(() => {
        return fromPromise(this.storage.get('showHomeAvailabilityTip')).pipe(
          map((response) => {
            if (typeof response !== 'boolean') {
              response = true;
            }
            return usersActions.setUserHomeAvailabilityTipSuccess({
              value: response,
            });
          }),
          catchError((error) =>
            of(
              usersActions.setUserHomeAvailabilityTipFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  public setShowHomeAvailabilityTip = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.setUserHomeAvailabilityTip),
      mergeMap((action) => {
        return fromPromise(this.storage.set('showHomeAvailabilityTip', action.value)).pipe(
          map(() => {
            return usersActions.setUserHomeAvailabilityTipSuccess({
              value: action.value,
            });
          }),
          catchError((error) =>
            of(
              usersActions.setUserHomeAvailabilityTipFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    ),
  );
}
