import { inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { addDays, isBefore, isDate, parseISO } from 'date-fns';
import { catchError, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { map, mergeMap } from 'rxjs/operators';
import { usersActions } from '../users.actions';

@Injectable()
export class UsersOnBoardingEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly storage = inject(Storage);

  public ngrxOnInitEffects(): Action {
    return usersActions.initUserOnBoarding();
  }

  public initOnBoarding = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.initUserOnBoarding),
      mergeMap(() => fromPromise(this.storage.create())),
      mergeMap(() => {
        return fromPromise(this.storage.get('onBoarding')).pipe(
          map((response) => {
            let date: Date = parseISO(response);
            if (!isDate(date)) {
              return usersActions.setUserOnBoarding({
                value: new Date(),
              });
            }

            return usersActions.setUserOnBoardingSuccess({
              value: isBefore(new Date(), addDays(date, 7)),
            });
          }),
          catchError((error) =>
            of(
              usersActions.setUserOnBoardingFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    ),
  );

  public setOnBoarding = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.setUserOnBoarding),
      mergeMap((action) => {
        return fromPromise(this.storage.set('onBoarding', action.value)).pipe(
          map(() => {
            return usersActions.setUserOnBoardingSuccess({
              value: true,
            });
          }),
          catchError((error) =>
            of(
              usersActions.setUserOnBoardingFailure({
                error: error,
              }),
            ),
          ),
        );
      }),
    ),
  );
}
